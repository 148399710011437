import React,{ Component } from "react";
import '../CSS/newRecord.css';
import '../CSS/login.css'
import Sidemenu from './Navbar/Sidemenu';
import GenericNavbar from './Navbar/GenericNavbar';


class Mintlist extends Component{
    constructor(prompt){
        super(prompt);
        this.state ={
            mintList:[]
        }
    }
    componentDidMount(){
        console.log(this.state.mintList);
        console.log(this.state.mintList.length);
        fetch(`http://52.60.64.107/api/nft/mint-report.php`)
        .then(response=>response.json())
        .then(data=>{
            this.setState({mintList:data.result})
            console.log(this.state.mintList);
        })
    }
    render(){
        return(
            <>
            <div className = "">
                <GenericNavbar/>
                <div className="container-fluid bg-black">
                <div className="row">
                    <Sidemenu />
                   
                    <div className="col-sm-10">
                        <div className="card reportCard">
                            <div className="card-title cardTitle2">
                                <h4 className = "cardTitle">List</h4>
                            </div>
                            <div className="card-content">
                            <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Address</th>
                                    <th>Price</th>
                                    <th>Image</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.mintList && this.state.mintList.length > 0 &&
                                <>
                                  {this.state.mintList.map((user,index)=>
                                    <tr key={index} >
                                        <td>{index+1} </td>
                                        <td>{user.account}</td>
                                        <td>{user.price?user.price:0} </td>
                                        <td><img style={{height:'50px' }} src={user.url} /> </td>
                                        <td>{user.create_date}</td>
                                    </tr>
                                    )}
                                </>
                                 }
                            </tbody>
                        </table>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div></>
        );
    }
}
export default Mintlist;