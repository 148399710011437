import React,{ Component } from "react";
import Navbar from "./Navbar";

class MarketPlace extends Component{
    constructor(props){
        super(props);
        this.state ={};
    }
    render(){
        return(
            <>
            <div className="overflow-hidden">
                <Navbar />
                <div class="container">
			    <div class="section mt-100">
			        <div class="section__head">
			            <h2 class="section__title mb-20"> Artworks</h2>
			            <div class="row justify-content-between align-items-center">
			                <div class="col-lg-auto">
			                    <div class="d-flex align-items-center">
			                        <span class="color_text txt_sm d-none d-sm-block mr-10">
			                            FILTER BY:
			                        </span>
			                        <ul class="menu_categories">
			                            <li class="d-flex switch_item">
			                        
			                                <input type="checkbox" id="switch1" /><label
			                                    for="switch1">Toggle</label>
			                                <span class="ml-10"> Has list price </span>
			                            </li>
			                            <li class="d-flex switch_item">
			                        
			                                <input type="checkbox" id="switch2" checked/><label
			                                    for="switch2">Toggle</label>
			                                <span class="ml-10"> Has open offer </span>
			                            </li>
			                            <li class="d-flex switch_item">
			                        
			                                <input type="checkbox" id="switch3" /><label
			                                    for="switch3">Toggle</label>
			                                <span class="ml-10"> Owned by creator </span>
			                            </li>
			                            <li class="d-flex switch_item">
			                        
			                                <input type="checkbox" id="switch4" /><label
			                                    for="switch4">Toggle</label>
			                                <span class="ml-10"> Has sold </span>
			                            </li>
			                        </ul>                    </div>
			                </div>
			                <div class="col-lg-auto">
			                    <div class="d-flex space-x-10 align-items-center sm:mt-20">
			                        <span class="color_text txt_sm"> SORT BY: </span>
			                        <div class="dropdown">
			                            <button
			                                class="btn btn-dark btn-sm dropdown-toggle"
			                                type="button"
			                                data-toggle="dropdown"
			                                aria-haspopup="true"
			                                aria-expanded="false">
			                                Recent Active
			                            </button>
			                            <div class="dropdown-menu">
			                                <a class="dropdown-item" href="#">Action</a>
			                                <a class="dropdown-item" href="#">Another action</a>
			                                <a class="dropdown-item" href="#">Something else
			                                    here</a>
			                            </div>
			                        </div>
			                    </div>
			                </div>
			            </div>
			        </div>
			
					<div class="row mb-30_reset">
			        	<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
			        		<div class="card__item four">
			        			<div class="card_body space-y-10">
			        				<div class="creators space-x-10">
			        					<div class="avatars space-x-3">
			        						<a href="">
			        							<img
			        								src="./img/avatars/avatar_1.png"
			        								alt="Avatar" class="avatar avatar-sm" />
			        						</a>
			        						<a href="">
			        							<p class="avatars_name txt_xs">@mickel_fenn</p>
			        						</a>
			        					</div>
			        					<div class="avatars space-x-3">
			        						<a href="">
			        							<img
			        								src="./img/avatars/avatar_2.png"
			        								alt="Avatar" class="avatar avatar-sm" />
			        						</a>
			        						<a href="">
			        							<p class="avatars_name txt_xs">@danil_pannini</p>
			        						</a>
			        					</div>
			        				</div>
			        				<div class="card_head">
			        					<a href="Item-details.html">
			        						<img src="./img/items/item_1.png"
			        							alt="" />
			        					</a>
			        
			        					<a href="#" class="likes space-x-3">
			        						<i class="ri-heart-3-fill"></i>
			        						<span class="txt_sm">1.2k</span>
			        					</a>
			        				</div>
			        				<h6 class="card_title">
			        					Colorful Abstract Painting
			        				</h6>
			        				<div class="card_footer d-block space-y-10">
			        					<div class="card_footer justify-content-between">
			        						<div class="creators">
			        							<p class="txt_sm"> 4 in stock</p>
			        						</div>
			        						<a href="#" class="">
			        							<p class="txt_sm">Price: <span
			        									class="color_green
			        									txt_sm">2.45 ETH</span></p>
			        						</a>
			        					</div>
			        					<div class="hr"></div>
			        					<div class="d-flex align-items-center space-x-10
			        						justify-content-between">
			        						<div class="d-flex align-items-center
			        							space-x-10">
			        							<i class="ri-history-line"></i>
			        							<a class="view_history" href="#" data-toggle="modal"
			        								data-target="#popup_history">
			        								<p class="color_text txt_sm"
			        									>View
			        									History</p>
			        							</a>
			        						</div>
			        						<a class="btn btn-sm btn-primary" href="#"
			        							data-toggle="modal" data-target="#popup_bid">Place
			        							Bid</a>
			        					</div>
			        				</div>
			        			</div>
			        		</div>
			        	</div>
			        	<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
			        		<div class="card__item four">
			        			<div class="card_body space-y-10">
			        				<div class="creators space-x-10">
			        					<div class="avatars space-x-3">
			        						<a href="">
			        							<img
			        								src="./img/avatars/avatar_3.png"
			        								alt="Avatar" class="avatar avatar-sm" />
			        						</a>
			        						<a href="">
			        							<p class="avatars_name txt_xs">@mazanov_sky</p>
			        						</a>
			        					</div>
			        					<div class="avatars space-x-3">
			        						<a href="">
			        							<img
			        								src="./img/avatars/avatar_4.png"
			        								alt="Avatar" class="avatar avatar-sm" />
			        						</a>
			        						<a href="">
			        							<p class="avatars_name txt_xs">@mucky_fennouni</p>
			        						</a>
			        					</div>
			        				</div>
			        				<div class="card_head">
			        					<a href="Item-details.html">
			        						<img src="./img/items/item_2.png"
			        							alt="" />
			        					</a>
			        
			        					<a href="#" class="likes space-x-3">
			        						<i class="ri-heart-3-fill"></i>
			        						<span class="txt_sm">13.2k</span>
			        					</a>
			        				</div>
			        				<h6 class="card_title">
			        					The girl with the firefly
			        				</h6>
			        				<div class="card_footer d-block space-y-10">
			        					<div class="card_footer justify-content-between">
			        						<div class="creators">
			        							<p class="txt_sm"> 12 in stock</p>
			        						</div>
			        						<a href="#" class="">
			        							<p class="txt_sm">Price: <span
			        									class="color_green
			        									txt_sm">2.55 ETH</span></p>
			        						</a>
			        					</div>
			        					<div class="hr"></div>
			        					<div class="d-flex align-items-center space-x-10
			        						justify-content-between">
			        						<div class="d-flex align-items-center
			        							space-x-10">
			        							<i class="ri-history-line"></i>
			        							<a class="view_history" href="#" data-toggle="modal"
			        								data-target="#popup_history">
			        								<p class="color_text txt_sm"
			        									>View
			        									History</p>
			        							</a>
			        						</div>
			        						<a class="btn btn-sm btn-primary" href="#"
			        							data-toggle="modal" data-target="#popup_bid">Place
			        							Bid</a>
			        					</div>
			        				</div>
			        			</div>
			        		</div>
			        	</div>
			        	<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
			        		<div class="card__item four">
			        			<div class="card_body space-y-10">
			        				<div class="creators space-x-10">
			        					<div class="avatars space-x-3">
			        						<a href="">
			        							<img
			        								src="./img/avatars/avatar_5.png"
			        								alt="Avatar" class="avatar avatar-sm" />
			        						</a>
			        						<a href="">
			        							<p class="avatars_name txt_xs">@jimmy_dom</p>
			        						</a>
			        					</div>
			        					<div class="avatars space-x-3">
			        						<a href="">
			        							<img
			        								src="./img/avatars/avatar_6.png"
			        								alt="Avatar" class="avatar avatar-sm" />
			        						</a>
			        						<a href="">
			        							<p class="avatars_name txt_xs">@kristian_sefroui</p>
			        						</a>
			        					</div>
			        				</div>
			        				<div class="card_head">
			        					<a href="Item-details.html">
			        						<img src="./img/items/item_3.png"
			        							alt="" />
			        					</a>
			        
			        					<a href="#" class="likes space-x-3">
			        						<i class="ri-heart-3-fill"></i>
			        						<span class="txt_sm">1.2k</span>
			        					</a>
			        				</div>
			        				
			        				<h6 class="card_title">
			        					Dodo hide the seek
			        				</h6>
			        				<div class="card_footer d-block space-y-10">
			        					<div class="card_footer justify-content-between">
			        						<div class="creators">
			        							<p class="txt_sm"> 6 in stock</p>
			        						</div>
			        						<a href="#" class="">
			        							<p class="txt_sm">Price: <span
			        									class="color_green
			        									txt_sm">2.45 ETH</span></p>
			        						</a>
			        					</div>
			        					<div class="hr"></div>
			        					<div class="d-flex align-items-center space-x-10
			        						justify-content-between">
			        						<div class="d-flex align-items-center
			        							space-x-10">
			        							<i class="ri-history-line"></i>
			        							<a class="view_history" href="#" data-toggle="modal"
			        								data-target="#popup_history">
			        								<p class="color_text txt_sm"
			        									>View
			        									History</p>
			        							</a>
			        						</div>
			        						<a class="btn btn-sm btn-primary" href="#"
			        							data-toggle="modal" data-target="#popup_bid">Place
			        							Bid</a>
			        					</div>
			        				</div>
			        			</div>
			        		</div>
			        	</div>
			        </div>    
				</div>
                </div>
            </div>
            </>
        )
    }
}
export default MarketPlace