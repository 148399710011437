import React, { Component } from 'react';


import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Navbar from './Navbar';

import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import KryptoBird from '../abis/KryptoBird.json'

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
			account: '',
			contract:null,
			totalSupply:0,
			kryptoBirdz:[],
			price:'',
			title:'',
			desc:'',
			name:'',
			mintImages: [],
			mintGroupImages: [],
			show:false,
			fileSize:'',
			fileType:'',
            user:'',
            enterBid:'',
            modalIsOpen:false,
            currentBit: 0
		}
        this.details = this.details.bind(this);
        this.placeBid = this.placeBid.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        //this.notify = this.notify.bind(this);
    }
    openModal() {
        this.setState({modalIsOpen: true})
      }
      notify() {
          console.log('ddd')
          toast('Hello Geeks 4',
          {position: toast.POSITION.BOTTOM_LEFT})
       toast('Hello Geeks 6',
          {position: toast.POSITION.BOTTOM_RIGHT})
       toast('Hello Geeks 5',
          {position: toast.POSITION.BOTTOM_CENTER})
       toast('Hello Geeks 1',
          {position: toast.POSITION.TOP_LEFT})
       toast('Hello Geeks 3',
          {position: toast.POSITION.TOP_RIGHT})
       toast('Hello Geeks 2',
          {position: toast.POSITION.TOP_CENTER})
      }
      closeModal() {
        this.setState({modalIsOpen: false})
      }
    async componentDidMount() {
		var user = localStorage.getItem('user');
		if(user){
		   this.setState({user: JSON.parse(user)});
		}
		await this.loadWeb3();
		await this.loadBlockchainData();
		await this.gelAllMint();
		await this.gelAllMintGroup();
        this.showNot()
	}
	gelAllMint(){
		fetch(`
		http://52.60.64.107/api/nft/mint-all.php`)
					  .then(response => response.json())
					  .then(data => {
						if(! data.error){
						  let getData =[];
                          console.log(data.result)
						  for (let i = 0; i < this.state.kryptoBirdz.length; i++) {
							 let link = this.state.kryptoBirdz[i];
							 let details = '';
							 for (let x = 0; x < data.result.length; x++) {
                                // console.log(data.result[x])
								if(link==data.result[x].url){
								   details = data.result[x];
								}
								if((data.result.length-1)==x){
                                    if(details !='' ){
                                        getData.push({url: link, details: details});
                                    }
								}
							 }                        
						  }
						  console.log(getData)
						  this.setState({mintImages: getData});
						}
					  });
	   }
       gelAllMintGroup(){
		fetch(`
		http://52.60.64.107/api/nft/mint-group-all.php`)
					  .then(response => response.json())
					  .then(data => {
						if(! data.error){
						  let getData =[];
                          let groupList = [];
						  for (let i = 0; i < this.state.kryptoBirdz.length; i++) {
							 let link = this.state.kryptoBirdz[i];
							 let details = '';
							 for (let x = 0; x < data.result.length; x++) {
                                
								if(link==data.result[x].url){
								   details = data.result[x];
								}
								if(((data.result.length-1)==x) &&(details !='') ){

                                    groupList.push(details.random_);
								   getData.push({url: link, details: details});
								}
							 }                        
						  }

                         var uniqueArray = groupList.filter(function(item, pos) {
                            return groupList.indexOf(item) == pos;
                        })
                        var setFinal=[];
                        for (let i = 0; i < uniqueArray.length; i++) {
                            var rendo = uniqueArray[i];
                            var img =[];
                            for (let g = 0; g < getData.length; g++) {
                                if(getData[g].details.random_ == rendo){
                                    if(img.length==0){
                                        img.push(getData[g]);
                                    }
                                }
                            }
                            var x = {code:rendo,mints: img}
                            
                            setFinal.push(x);
                        }
						  console.log(setFinal)

						  this.setState({mintGroupImages: setFinal});
						}
					  });
	   }

	async loadWeb3() {
		const provider = await detectEthereumProvider();
  
		// modern browsers
		// if there is a provider then lets
		// lets log that it's working and access the window from the doc
		// to set Web3 to the provider 
		
		if(provider) {
			console.log('ethereum wallet is connected')
			window.web3 = new Web3(provider)
		} else {
			// no ethereum provider
			console.log('no ethereum wallet detected')
		}
	}
  
	async loadBlockchainData() {
		const web3 = window.web3
		const accounts = await web3.eth.getAccounts()
		this.setState({account:accounts[0]})
  
		// create a constant js variable networkId which 
		//is set to blockchain network id 
		const networkId = await web3.eth.net.getId()
		const networkData = KryptoBird.networks[networkId]
		 if(networkData) {
			 // EXERCISE TIME!!!! :)
			 // 1. create a var abi set to the Kryptobird abi
			 // 2. create a var address set to networkData address
			 // 3. create a var contract which grabs a 
			 //new instance of web3 eth Contract  
			 // 4. log in the console the var contract successfully - GOOD LUCK!!!!
  
			 const abi = KryptoBird.abi;
			 const address = networkData.address; 
			 const contract = new web3.eth.Contract(abi, address)
			 this.setState({contract})
  
			 // call the total supply of our Krypto Birdz 
			 // grab the total supply on the front end and log the results
			 // go to web3 doc and read up on methods and call 
			 const totalSupply = await contract.methods.totalSupply().call()
			this.setState({totalSupply})
			// set up an array to keep track of tokens 
			// load KryptoBirdz
			for(let i = 1; i <= totalSupply; i++) {
				const KryptoBird = await contract.methods.kryptoBirdz(i - 1).call()
				// how should we handle the state on the front end? 
				this.setState({
					kryptoBirdz:[...this.state.kryptoBirdz, KryptoBird]
				})
			}
		 } else {
			 //window.alert('Smart contract not deployed')
		 }
	}

	mint = (kryptoBird) => {
		this.state.contract.methods.mint(kryptoBird).send({from:this.state.account})
		.once('receipt', (receipt)=> {
			this.setState({
				kryptoBirdz:[...this.state.kryptoBirdz, KryptoBird]
			})
		})  
	}
    details(details){
        var user = localStorage.getItem('user');
        if(user){
            //details.account = this.state.account;
            console.log(details)
            this.props.history.push("/details", { state: details});
            //this.setState({loged: true})
        }else{
            window.location.href = '/login'
        }
    }
    placeBid(kryptoBird,v){
        console.log(this.state.enterBid)
        console.log(kryptoBird);
        console.log(v);
        var data = {ueserId: this.state.user.id, mintid: kryptoBird.details.id, enterBid: v};
        fetch(`
		http://52.60.64.107/api/nft/bid.php?id=${kryptoBird.details.id}&ueserId=${this.state.user.id}&enterBid=${v}`)
					  .then(response => response.json())
					  .then(data => {
						if(! data.error){
                            this.setState({currentBit: kryptoBird.details.id})
                        }
                    });

    }

    handleChange = (e) => {
        console.log(e.target.value)
        /* setValue(e.target.value); */
      };
      showNot(){
        toast('Hello Geeks 3',
        {position: toast.POSITION.TOP_RIGHT})
      }
    render() {

        return (
            <>
            <div className="overflow-hidden">
                <Navbar />
                <div class="hero__1">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="hero__left space-y-20">
                                    <h1 class="hero__title">
                                    Discover or Mint digital assets, art and collect NFTs
                                    </h1>
                                    <p class="hero__text txt">NFT LIMITED SERIES.com is a shared liquidity
                                    NFT Limited Series provides Minting
Services for multiple industries for various use cases..</p>
                                    <div class="space-x-20 d-flex flex-column flex-md-row
                                        sm:space-y-20">
                                        <a class="btn btn-primary" href="">View
                                            market</a>
                                            {this.state.user.id && 
                                        <a class="btn btn-white" 
                                        href="/upload">
                                            Upload your item</a>}
                                        {this.state.user =='' && 
                                        <a class="btn btn-white" 
                                        href="/login">
                                            Upload your item</a>}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <img class="img-fluid w-full" id="img_js"
                                    src="./img/bg/in_hero1.png"
                                    alt="" />
                            </div>
                        </div>

                    </div>
                </div>
                
                <div class="section__artists mt-100">
                    <div class="container">
                        <div class="space-y-30">
                            <div class="section_head">
                                <h2 class="section__title">Top Artists</h2>
                            </div>
                            <div class="section_body swiper_artists">
                                <div class="swiper-wrapper position-relative">
                                    <div class="swiper-slide">
                                        <div class="creator_item creator_card rounded_border space-x-10">
                                            <div class="avatars space-x-10">
                                                <div class="media">
                                                    <div class="badge">
                                                        <img src="./img/icons/Badge.svg" alt="" />
                                                    </div>
                                                    <a href="">
                                                        <img src="./img/avatars/avatar_1.png" alt="Avatar" class="avatar avatar-md" />
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href=""><p class="avatars_name color_black">@xander_hall...</p></a>
                                                    <span class="price color_green">16.58 ETH</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="creator_item creator_card rounded_border space-x-10">
                                            <div class="avatars space-x-10">
                                                <div class="media">
                                                    <div class="badge">
                                                        <img src="./img/icons/Badge.svg" alt="" />
                                                    </div>
                                                    <a href="">
                                                        <img src="./img/avatars/avatar_2.png" alt="Avatar" class="avatar avatar-md" />
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href=""><p class="avatars_name color_black">@hamza_pitts...</p></a>
                                                    <span class="price color_green">14.55 ETH</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="swiper-slide">
                                        <div class="creator_item creator_card rounded_border space-x-10">
                                            <div class="avatars space-x-10">
                                                <div class="media">
                                                    <div class="badge">
                                                        <img src="./img/icons/Badge.svg" alt="" />
                                                    </div>
                                                    <a href="">
                                                        <img src="./img/avatars/avatar_3.png" alt="Avatar" class="avatar avatar-md" />
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href=""><p class="avatars_name color_black">@nathan_walls...</p></a>
                                                    <span class="price color_green">24.13 ETH</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="swiper-slide">
                                        <div class="creator_item creator_card rounded_border space-x-10">
                                            <div class="avatars space-x-10">
                                                <div class="media">
                                                    <div class="badge">
                                                        <img src="./img/icons/Badge.svg" alt="" />
                                                    </div>
                                                    <a href="">
                                                        <img src="./img/avatars/avatar_4.png" alt="Avatar" class="avatar avatar-md" />
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href=""><p class="avatars_name color_black">@kelton_collier...</p></a>
                                                    <span class="price color_green">62.68 ETH</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="swiper-slide">
                                        <div class="creator_item creator_card rounded_border space-x-10">
                                            <div class="avatars space-x-10">
                                                <div class="media">
                                                    <div class="badge">
                                                        <img src="./img/icons/Badge.svg" alt="" />
                                                    </div>
                                                    <a href="">
                                                        <img src="./img/avatars/avatar_5.png" alt="Avatar" class="avatar avatar-md" />
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href=""><p class="avatars_name color_black">@cade_glover...</p></a>
                                                    <span class="price color_green">32.48 ETH</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-pagination"></div>

                                <div class="swiper-button-prev"></div>
                                <div class="swiper-button-next"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-100">
                    <div class="container">
                        <div class="section__head">
                            <div class="d-md-flex sm:space-y-20 space-x-20 justify-content-between align-items-center">
                                <h2 class="section__title text-center">Explore</h2>
                                <ul class="menu_categories space-x-20">
                                    <li>
                                        <a href="#" class="color_brand">
                                            <span> All </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#"> <i class="ri-gamepad-line"></i> <span> Games </span> </a>
                                    </li>
                                    <li>
                                        <a href="#"> <i class="ri-brush-line"></i> <span> Art </span> </a>
                                    </li>
                                    <li>
                                        <a href="#"> <i class="ri-stock-line"></i> <span> Trading Cards </span> </a>
                                    </li>
                                    <li>
                                        <a href="#"> <i class="ri-music-line"></i> <span> Music </span> </a>
                                    </li>
                                    <li>
                                        <a href="#"> <i class="ri-global-line"></i> <span> Domain Names </span> </a>
                                    </li>
                                    <li>
                                        <a href="#"> <i class="ri-emotion-laugh-line"></i> <span> Memes </span> </a>
                                    </li>
                                    <li>
                                        <a href="#"> <i class="ri-layout-4-line"></i> <span> Collectibles </span> </a>
                                    </li>
                                </ul>
                                <div class="dropdown text-center">
                                    <button class="btn btn-white btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Recent Active
                                    </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
{/* 
                        <button onClick={this.notify}>Click Me!</button> */}

                        {/* <button onClick={this.openModal}>Open Modal33</button>
      <Modal
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
        style={this.state.customStyles}
        contentLabel="Example Modal"
      >
        <h2>Hello</h2>
        <button onClick={this.closeModal}>close</button>
        <div>I am a modal</div>
        <form>
          <input />
          <button>tab navigation</button>
          <button>stays</button>
          <button>inside</button>
          <button>the modal</button>
        </form>
      </Modal> */}
                                                
                        {this.state.mintImages.map((kryptoBird, key)=>{
                                return(
                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={key}>
                                <div class="card__item four">
                                    <div class="card_body space-y-10">
                                        
                                        <div class="creators space-x-10">
                                            <div class="avatars space-x-3">
                                                <a  onClick={() => this.details(kryptoBird)}>
                                                    <img src="./img/avatars/avatar_1.png" alt="Avatar" class="avatar avatar-sm" />
                                                </a>
                                                <a onClick={() => this.details(kryptoBird)}>
                                                    <p class="avatars_name txt_xs">@{kryptoBird.details.name} { kryptoBird.details.id} </p>
                                                </a>
                                            </div>
                                            {/* <div class="avatars space-x-3">
                                                <a href="">
                                                    <img src="./img/avatars/avatar_2.png" alt="Avatar" class="avatar avatar-sm" />
                                                </a>
                                                <a href="">
                                                    <p class="avatars_name txt_xs">@danil_pannini</p>
                                                </a>
                                            </div> */}
                                        </div>
                                        <div class="card_head">
                                            <a onClick={() => this.details(kryptoBird)} >
                                                <img
                                                    src={kryptoBird.url}
                                                    alt="item
                                                    img"
                                                />
                                            </a>
                                            <a href="#" class="likes space-x-3">
                                                <i class="ri-heart-3-fill"></i>
                                                <span class="txt_sm">1.2k</span>
                                            </a>
                                        </div>
                                        

                                        <h6 class="card_title">
                                            {kryptoBird.details.title &&
                                            <a class="color_black" onClick={() => this.details(kryptoBird)}>
                                                {kryptoBird.details.title}
                                            </a>
                                            }
                                        </h6>
                                        <div class="card_footer d-block space-y-10">
                                            <div class="card_footer justify-content-between">
                                                <div class="creators">
                                                    <p class="txt_sm">4 in stock</p>
                                                </div>
                                                <a href="#" class="">
                                                    {kryptoBird.details.price &&
                                                    <p class="txt_sm">Price: 
                                                    <span class="color_green txt_sm">{kryptoBird.details.price} </span>
                                                    </p>}
                                                </a>
                                            </div>
                                            <div class="hr"></div>
                                            {kryptoBird.details.min_price &&
                                            <p>Min Price: 
                                            <span class="color_green txt_sm">{ kryptoBird.details.min_price}  ETH
                                                </span> </p>
                                             }
                                                <br />
                                            <div class="d-flex align-items-center space-x-10 justify-content-between">
                                                
                                                <div class="d-flex align-items-center space-x-5">
                                                    <i class="ri-history-line"></i>
                                                    <a onClick={() => this.details(kryptoBird)} data-toggle="modal" data-target="#popup_history">
                                                        <p class="color_text txt_sm view_history">
                                                            View
                                                        </p>
                                                    </a>
                                                    
                                                </div>
                                                
                                                <input type="text" className='form-control bid' 
                                                placeholder='Bid' style={{borderColor: 'red !important' }}
                                                value={kryptoBird.details.enterBid}
                                                onChange={(e)=>{
                                                    kryptoBird.details.enterBid = e.target.value;
                                                    kryptoBird.details.submited = 1;//e.target.value;
                                                    console.log( kryptoBird.details)
                                                    
                                                 }}
                                               /*  onChange={this.handleChange} */
                                                />
                                                <a class="btn btn-sm btn-primary" 
                                                onClick={() => this.placeBid(kryptoBird,kryptoBird.details.enterBid )}
                                                >Place Bid</a>
                                            </div> {
                                            this.state.currentBit == kryptoBird.details.id &&
                                           <>
                                            <div class="hr"></div>
                                            <p className='text-right text-danger'>Bid submitted </p>
                                            </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })} 
                        </div>
                        {/* <div class="d-flex justify-content-center">
                            <a href="" class="btn btn-sm btn-white">
                                <i class="ri-restart-line"></i>
                                View all items
                            </a>
                        </div> */}
                    </div>
                </div>


                <div class="section mt-100">
                    <div class="container">
                        <div class="section__head">
                            <div class="d-flex justify-content-between align-items-center">
                                <h2 class="section__title">Collections</h2>
                                {/* <a href="" class="btn btn-dark btn-sm"> View </a> */}
                            </div>
                        </div>

                        <div class="row justify-content-center mb-30_reset">
                             { this.state.mintGroupImages && this.state.mintGroupImages.length >0  && 
                             <div className='row'>
                            {this.state.mintGroupImages.map((mints, key)=>{ 
                                return(
                                    <div class="col-lg-4 col-md-6 col-sm-8" key={key}>
                                        <div class="collections space-y-10 mb-30">
                                            <a  onClick={() => this.details(mints.mints[0])} >
                                                <div class="collections_item">
                                                    <div class="images-box space-y-10">
                                                
                                                       { mints.mints.map((person, index) => (
                                                           <img key={index} src={person.url} alt=""  />
                                                           
                                                        ))
                                                        }
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </a>
                                            <div class="collections_footer justify-content-between">
                                                <h5 class="collection_title">
                                                { mints.mints.map((person, index) => (
                                                           <a href="" key={index} >
                                                           {person.details.title}
                                                       </a>
                                                           
                                                        ))
                                                        }
                                                    
                                                    </h5>
                                                <a href="#" class="likes space-x-3">
                                                    <i class="ri-heart-3-fill"></i>
                                                    <span class="txt_md">2.1k</span>
                                                </a>
                                            </div>
                                            <div class="creators space-x-10">
                                                <span class="color_text txt_md"> 5 items · Created by</span>
                                                <div class="avatars space-x-5">
                                                    <a href="">
                                                        <img src="./img/avatars/avatar_2.png" alt="Avatar" class="avatar avatar-sm" />
                                                    </a>
                                                </div>
                                                <a href="">
                                                    <p class="avatars_name txt_sm">
                                                        {mints.mints[0].details.name}
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                                })}
                            
                            </div>
                            }

                        </div>
                    </div>
                </div>
                <div class="call2action">
                    <div class="container">
                        <div class="row justify-content-between align-items-center sm:space-y-20">
                            <div class="col-md-6">
                                <div class="space-y-20">
                                    <h1 class="text-white">Start your own collection today</h1>
                                    <p class="color_text section__text">NFT LIMITED SERIES.com is a shared liquidity NFT market smart contract which is used by multiple websites to provide the users the best possible experience.</p>
                                    <a href="" class="btn btn-primary">Start Collecting</a>
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <img class="img-fluid img__logo" src="./img/logos/Logo_illustrstion.png" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>


                </div>
            </>
        );
    }
}

export default Home;