import React ,{ Component } from "react";
import Navbar from "./Navbar";

class UploadType extends Component{
    constructor(props){
        super(props);
        this.state = {
			account: '',
			contract:null,
			totalSupply:0,
			kryptoBirdz:[],
			price:'',
			title:'',
			desc:'',
			name:'',
			mintImages: [],
			show:false,
			fileSize:'',
			fileType:'',
			category:'',
			progress: 0,
			links: ''
		}
    }

    render(){
        return(
            <>
            <div className="overflow-hidden">
                <Navbar />
                <div class="hero__upload">
			    <div class="container">
			        <div class="space-y-20">
			            <h1 class="text-center">Create single collectible</h1>
			            <p class="hero__text text-center">Choose “Single” if you want your
			                collectible
			                to
			                be one of a kind or “Multiple” if you want to sell one
			                collectible
			                multiple times</p>
			        </div>
			    </div>
			</div>
                <div class="row justify-content-center">
					<div class="col-lg-6">
						<div class="row">
							<div class="col-lg-6">
								<div class="box text-center">
									<img class="icon mb-20"
										src="./img/icons/single.svg"
										alt="" />
									<div class="space-y-10">
										<h5>Sell One Time</h5>
										<p class="color_text">your collectible want to be
											one of
											a kind</p>
										<div class="hr"></div>
										<a class="btn btn-grad btn" href="http://99.79.110.129/upload" target="_blank">
											create single
										</a>
			
									</div>
								</div>
							</div>
							<div class="col-lg-6">
								<div class="box text-center">
									<img class="icon mb-20"
										src="./img/icons/multiple.svg"
										alt="" />
									<div class="space-y-10">
										<h5> Sell Multiple Time</h5>
										<p class="color_text">your collectible want to be
											one of
											a kind</p>
										<div class="hr"></div>
										<a class="btn btn-grad btn" href="http://99.79.110.129/upload-multiple" target="_blank">
											create multiple
										</a>
									</div>
								</div>
							</div>
			
						</div>
			
					</div>
				</div>
			
            </div>
            </>
        );
    }

}
export default UploadType;