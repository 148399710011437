import React ,{ Component } from "react";
import Navbar from "./Navbar";

import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import KryptoBird from '../abis/KryptoBird.json'

class Details extends Component{
    constructor(props){
        super(props);
        this.state = {
			account: '',
			contract:null,
			totalSupply:0,
			kryptoBirdz:[],
			price:'',
			title:'',
			desc:'',
			name:'',
			mintImages: [],
			show:false,
			fileSize:'',
			fileType:'',
            details: ''
		}
    }
	async componentDidMount() {
        console.log(this.props)
        setTimeout(() => {
            this.setState({details: this.props.history.location.state.state.details })
        }, 200);
		/* var user = localStorage.getItem('user');
		if(!user){
		   this.props.history.push('/login');
		} */
		await this.loadWeb3();
		await this.loadBlockchainData();
		await this.gelAllMint();
	}
	gelAllMint(){
		fetch(`
		http://52.60.64.107/api/nft/mint-all.php`)
					  .then(response => response.json())
					  .then(data => {
						if(! data.error){
						  let getData =[];
						  for (let i = 0; i < this.state.kryptoBirdz.length; i++) {
							 let link = this.state.kryptoBirdz[i];
							 let details = '';
							 for (let x = 0; x < data.result.length; x++) {
								if(link==data.result[x].url){
								   details = data.result[x];
								}
								if((data.result.length-1)==x){
								   getData.push({url: link, details: details});
								}
							 }                        
						  }
						  console.log(getData)
						  this.setState({mintImages: getData});
						}
					  });
	   }

	async loadWeb3() {
		const provider = await detectEthereumProvider();
  
		// modern browsers
		// if there is a provider then lets
		// lets log that it's working and access the window from the doc
		// to set Web3 to the provider 
		
		if(provider) {
			console.log('ethereum wallet is connected')
			window.web3 = new Web3(provider)
		} else {
			// no ethereum provider
			console.log('no ethereum wallet detected')
		}
	}
  
	async loadBlockchainData() {
		const web3 = window.web3
		const accounts = await web3.eth.getAccounts()
		this.setState({account:accounts[0]})
  
		// create a constant js variable networkId which 
		//is set to blockchain network id 
		const networkId = await web3.eth.net.getId()
		const networkData = KryptoBird.networks[networkId]
		 if(networkData) {
			 // EXERCISE TIME!!!! :)
			 // 1. create a var abi set to the Kryptobird abi
			 // 2. create a var address set to networkData address
			 // 3. create a var contract which grabs a 
			 //new instance of web3 eth Contract  
			 // 4. log in the console the var contract successfully - GOOD LUCK!!!!
  
			 const abi = KryptoBird.abi;
			 const address = networkData.address; 
			 const contract = new web3.eth.Contract(abi, address)
			 this.setState({contract})
  
			 // call the total supply of our Krypto Birdz 
			 // grab the total supply on the front end and log the results
			 // go to web3 doc and read up on methods and call 
			 const totalSupply = await contract.methods.totalSupply().call()
			this.setState({totalSupply})
			// set up an array to keep track of tokens 
			// load KryptoBirdz
			for(let i = 1; i <= totalSupply; i++) {
				const KryptoBird = await contract.methods.kryptoBirdz(i - 1).call()
				// how should we handle the state on the front end? 
				this.setState({
					kryptoBirdz:[...this.state.kryptoBirdz, KryptoBird]
				})
			}
		 } else {
			 //window.alert('Smart contract not deployed')
		 }
	}

	mint = (kryptoBird) => {
		this.state.contract.methods.mint(kryptoBird).send({from:this.state.account})
		.once('receipt', (receipt)=> {
			this.setState({
				kryptoBirdz:[...this.state.kryptoBirdz, KryptoBird]
			})
		})  
	}
    render(){
        return(
            <>
                <div className="overflow-hidden">
                <Navbar />
                <div class="container">
    <a href="/" class="btn btn-white btn-sm my-40">
        Back to home
    </a>
    <div class="item_details">
        <div class="row sm:space-y-20">
            <div class="col-lg-6">
            { this.state.details.group_mint && 
                <div>
                    {this.state.details.group_mint.map((book,index)=>
                        <img key={index} src={book } className="img-thumbnail"
                        alt="" style={{height:'80px'}}  />
                    )}
                </div>
                }
                <img class="item_img" src={this.state.details.url } alt="" />
                
            </div>
            <div class="col-lg-6">
                <div class="space-y-20">
                    <h3>{this.state.details.title } </h3>
                    <div class="d-flex justify-content-between">
                        <div class="space-x-10 d-flex align-items-center">
                            {/* <p>1 of 1</p>
                            <a href="#" class="likes space-x-3">
                                <i class="ri-heart-3-fill"></i>
                                <span class="txt_sm">2.1k</span>
                            </a> */}
                        </div>
                        
                    </div>
                    <div class="box">
                        <div class="space-y-20">
                            <div class="d-flex justify-content-between mb-30_reset">
                                <ul class="nav nav-tabs d-flex space-x-10 mb-30" role="tablist">
                                    <li class="nav-item">
                                        <a class="btn btn-white btn-sm active" data-toggle="tab" href="#tabs-1" role="tab"> Details</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="hr"></div>
                            <div class="tab-content">
                                <div class="tab-pane active" id="tabs-1" role="tabpanel">
                                    <p>{this.state.details.desce }</p>
                                </div>
                                <div class="tab-pane" id="tabs-2" role="tabpanel">
                                    <p>No active bids yet. Be the first to make a bid!</p>
                                </div>
                                <div class="tab-pane space-y-20" id="tabs-3" role="tabpanel">
                                    <div class="creator_item creator_card space-x-10">
                                        <div class="avatars space-x-10">
                                            <div class="media">
                                                <div class="badge">
                                                    <img src="assets/img/icons/Badge.svg" alt="" />
                                                </div>
                                                <a href="">
                                                    <img src="assets/img/avatars/avatar_1.png" alt="Avatar" class="avatar avatar-md" />
                                                </a>
                                            </div>
                                            <div>
                                                <p class="color_black">Bid accepted <span class="color_brand">1 ETH</span> by <a class="color_black txt _bold" 
                                                href="">ayoub</a></p>
                                                <span class="date color_text">28/06/2021, 12:08</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="creator_item creator_card space-x-10">
                                        <div class="avatars space-x-10">
                                            <div class="media">
                                                <div class="badge">
                                                    <img src="assets/img/icons/Badge.svg" alt="" />
                                                </div>
                                                <a href="">
                                                    <img src="assets/img/avatars/avatar_2.png" alt="Avatar" class="avatar avatar-md" />
                                                </a>
                                            </div>
                                            <div>
                                                <p class="color_black">Bid accepted <span class="color_brand">3 ETH</span> by <a class="color_black txt _bold" 
                                                href="">monir</a></p>
                                                <span class="date color_text">22/05/2021, 12:08</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="numbers">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="space-y-5">
                                    <p class="color_text">Minimum bid</p>
                                    <h4>  <span class="txt_sm color_text">{this.state.details.min_price } ETH </span></h4>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hr2"></div>
                    <div class="row">
                            <div class="col-lg-6">
                                <div class="space-y-5">
                                    <p class="color_text">Bids</p>
                                    {this.state.details.bid &&
                                        <>
                                        {this.state.details.bid.map((bid,index)=>
                                            <h4>  
                                            {/* <span class="txt_sm color_text">User:  {bid.user } </span> */}
                                            <span class="txt_sm color_text">{bid.bid } ETH </span>
                                            </h4>
                                        )}
                                        
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    <div class="creators">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="avatars space-x-5">
                                    <div class="media">
                                        {/* <a href="">
                                            <img src="assets/img/avatars/avatar_3.png" alt="Avatar" class="avatar avatar-sm" />
                                        </a> */}
                                    </div>
                                    <div>
                                        <a >
                                            <p class="avatars_name color_black">@{this.state.details.name} </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-lg-6">
                                <div class="avatars space-x-5">
                                    <div class="media">
                                        <div class="badge">
                                            <img class="badge" src="assets/img/icons/Badge.svg" alt="" />
                                        </div>
                                        <a href="">
                                            <img src="assets/img/avatars/avatar_2.png" alt="Avatar" class="avatar avatar-sm" />
                                        </a>
                                    </div>
                                    <div>
                                        <a href="">
                                            <p class="avatars_name color_black">@makinzi_jamy...</p>
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div class="d-flex space-x-20">
                        {/* <a href="#" class="btn btn-primary btn-lg" data-toggle="modal" data-target="#popup_buy"> Buy Now</a> */}
                        <a href="#" class="btn btn-grad btn-lg" data-toggle="modal" data-target="#popup_bid"> Place bid</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

            </div>
            </>
        );
    }
}
export default Details;