import React,{ Component } from "react";
import '../CSS/newRecord.css';
import '../CSS/login.css'
import Sidemenu from './Navbar/Sidemenu';
import GenericNavbar from './Navbar/GenericNavbar';
import { data } from "jquery";


class Userlist extends Component{
    constructor(prompt){
        super(prompt);
        this.state ={
            userlist:[]
        }
        this.userActive = this.userActive.bind(this)
        this.userDactive = this.userDactive.bind(this)
        this.getAlluser = this.getAlluser.bind(this)
    }
    getAlluser(){
        fetch(`http://52.60.64.107/api/nft/user-report.php`)
        .then(response=>response.json())
        .then(data=>{
            this.setState({userlist:data.result});
        })
    }
    componentDidMount(){
        this.getAlluser();
    }
    userActive(user){
        fetch(`http://52.60.64.107/api/nft/user-status.php?id=${user.id}&status=1`)
        .then(response=>response.json())
        .then(data=>{
            this.getAlluser();
        })
    }
    userDactive(user){
        fetch(`http://52.60.64.107/api/nft/user-status.php?id=${user.id}&status=0`)
        .then(response=>response.json())
        .then(data=>{
            this.getAlluser();
        })
    }
    render(){
        return(
            <>
            <div className = "">
                <GenericNavbar/>
                <div className="container-fluid bg-black">
                <div className="row">
                    <Sidemenu />
                   
                    <div className="col-sm-10">
                        <div className="card reportCard">
                            <div className="card-title cardTitle2">
                                <h4 className = "cardTitle">List</h4>
                            </div>
                            <div className="card-content">
                            <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Title</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.userlist && this.state.userlist.length >0 &&
                                <>
                                 {this.state.userlist.map((user,index)=>
                                    <tr key={index} >
                                        <td>{index+1} </td>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.title}</td>
                                        <td>{user.create_date}</td>
                                        <td>
                                            { user.active_status ==0 &&
                                            <button type="button"
                                            className="btn btn-success"
                                            onClick={()=> this.userActive(user)}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus" viewBox="0 0 16 16">
                                                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                                                <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                                                </svg>
                                            </button>}
                                            { user.active_status ==1 &&
                                            <button type="button"
                                            className="btn btn-danger"
                                            onClick={()=> this.userDactive(user)}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-x" viewBox="0 0 16 16">
                                                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                                                <path fill-rule="evenodd" d="M12.146 5.146a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
                                                </svg>
                                            </button>}
                                        </td>
                                    </tr>
                                    )}
                                </>
                                 }
                            </tbody>
                        </table>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div></>
        );
    }
}
export default Userlist;