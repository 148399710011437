import React ,{ Component } from "react";
import Navbar from "./Navbar";

import 'font-awesome/css/font-awesome.min.css';

import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import KryptoBird from '../abis/KryptoBird.json'

class Profile extends Component{
    constructor(props){
        super(props);
        this.state = {
            user:'',
            account: '',
			contract:null,
			totalSupply:0,
			kryptoBirdz:[],
			price:'',
			title:'',
			desc:'',
			name:'',
			mintImages: [],
			show:false,
			fileSize:'',
			fileType:'',
        };
    }
    async componentDidMount(){
        var user = localStorage.getItem('user');
        if(user){
            this.setState({user: JSON.parse(user)});
        }else{
            this.props.history.push('/login');
        }

        await this.loadWeb3();
		await this.loadBlockchainData();
        await this.gelAllMint();
    }
    gelAllMint(){
		fetch(`
		http://52.60.64.107/api/nft/mint-all.php`)
					  .then(response => response.json())
					  .then(data => {
						if(! data.error){
						  let getData =[];
						  for (let i = 0; i < this.state.kryptoBirdz.length; i++) {
							 let link = this.state.kryptoBirdz[i];
							 let details = '';
							 for (let x = 0; x < data.result.length; x++) {
								if(link==data.result[x].url){
								   details = data.result[x];
								}
								if((data.result.length-1)==x){
								   getData.push({url: link, details: details});
								}
							 }                        
						  }
						  console.log(getData)
						  this.setState({mintImages: getData});
						}
					  });
	   }
    async loadWeb3() {
		const provider = await detectEthereumProvider();
  
		// modern browsers
		// if there is a provider then lets
		// lets log that it's working and access the window from the doc
		// to set Web3 to the provider 
		
		if(provider) {
			console.log('ethereum wallet is connected')
			window.web3 = new Web3(provider)
		} else {
			// no ethereum provider
			console.log('no ethereum wallet detected')
		}
	}
  
	async loadBlockchainData() {
		const web3 = window.web3
        console.log(web3)
		const accounts = await web3.eth.getAccounts();
        console.log(accounts)
		this.setState({account:accounts[0]})
        console.log(accounts[0])
		// create a constant js variable networkId which 
		//is set to blockchain network id 
		const networkId = await web3.eth.net.getId()
		const networkData = KryptoBird.networks[networkId]
		 if(networkData) {
			 // EXERCISE TIME!!!! :)
			 // 1. create a var abi set to the Kryptobird abi
			 // 2. create a var address set to networkData address
			 // 3. create a var contract which grabs a 
			 //new instance of web3 eth Contract  
			 // 4. log in the console the var contract successfully - GOOD LUCK!!!!
  
			 const abi = KryptoBird.abi;
			 const address = networkData.address; 
			 const contract = new web3.eth.Contract(abi, address)
			 this.setState({contract})
  
			 // call the total supply of our Krypto Birdz 
			 // grab the total supply on the front end and log the results
			 // go to web3 doc and read up on methods and call 
			 const totalSupply = await contract.methods.totalSupply().call()
			this.setState({totalSupply})
			// set up an array to keep track of tokens 
			// load KryptoBirdz
			for(let i = 1; i <= totalSupply; i++) {
				const KryptoBird = await contract.methods.kryptoBirdz(i - 1).call()
				// how should we handle the state on the front end? 
				this.setState({
					kryptoBirdz:[...this.state.kryptoBirdz, KryptoBird]
				})
			}
		 } else {
			 //window.alert('Smart contract not deployed')
		 }
	}

    render(){
        return(
            <>
            <div className="overflow-hidden">
                <Navbar />
                <div class="mb-100">
				<div class="hero__profile">
					<div class="cover">
						<img src="./img/bg/prrofile.png" alt="" /> {/** ../.././img/bg/prrofile.png */}
					</div>
					<div class="infos">
						<div class="container">
							<div class="row flex-wrap align-items-center justify-content-between sm:space-y-50">
								<div class="col-md-auto mr-20">
									<div class="avatars d-flex space-x-20
										align-items-center">
										<div class="avatar_wrap">
											<img class="avatar avatar-lg"
												src="./img/avatars/avatar_4.png"
												alt="avatar" />
										</div>
										<h5>@{this.state.user.name} </h5>
									</div>
								</div>
								<div class="col-md-auto">
									<div class="d-sm-flex flex-wrap align-items-center
										space-x-20 mb-20_reset d-sm-block">
										<div class="mb-20">
											<div class="copy">
												<span class="color_text"> {this.state.account }
												</span>
												<a href="#">
                                                <i className="fa fa-clone"></i>
												</a>
											</div>
										</div>
										<div class="d-flex flex-wrap align-items-center
											space-x-20">
											<div class="mb-20">
												<a href="" class="btn btn-dark btn-sm">
													Follow
												</a>
			
											</div>
											<div class="mb-20">
												<div class="share">
													<div class="icon">
														<a href="#"> 
                                                            <i className="fa fa-share-alt ff"></i>
														</a>
													</div>
													<div class="dropdown__popup">
														<ul class="space-y-10">
															<li> <a href="#"> <i
																		class="ri-facebook-line"></i>
																</a>
															</li>
															<li> <a href="#"> <i
																		class="ri-messenger-line"></i>
																</a>
															</li>
															<li> <a href="#"> <i
																		class="ri-whatsapp-line"></i>
																</a>
															</li>
															<li> <a href="#"> <i
																		class="ri-youtube-line"></i>
																</a>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div class="mb-20">
												<div class="more">
													<div class="icon">
														<a href="#"> <i
																class="fa fa-ellipsis-v"></i>
														</a>
													</div>
													<div class="dropdown__popup">
														<ul class="space-y-10">
															<li>
																<a href="#"
																	class="space-x-10
																	d-flex">
																	<i class="ri-flag-line"></i>
																	<span> Report </span>
																</a>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

            <div class="container">
    <div class="row justify-content-center">
        <div class="col-lg-3 col-md-7 order-md-0 order-1">
            <div class="profile__sidebar">
                <div class="space-y-40">
                    {/* <div class="space-y-10">
                        <h5>About me</h5>
                        <div class="box space-y-20">
                            <p>
                                {this.state.user.bio}
                            </p>
                            <div class="row">
                                <div class="col-6">
                                    <span class="txt_sm color_text">Collections</span>
                                    <h4>105</h4>
                                </div>
                                <div class="col-6">
                                    <span class="txt_sm color_text">Creations</span>
                                    <h4>406</h4>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div class="space-y-10">
                        <h5>Follow me</h5>
                        <div class="box">
                            <ul class="social_profile space-y-10 overflow-hidden">
                                <li>
                                    <a href="#">
                                        <i class="fa fa-facebook"></i>
                                        <span class="color_text">facebook/</span>
                                        @creabik
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-comments-o"></i>
                                        <span class="color_text"> messenger/</span>
                                        @creabik
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-whatsapp"></i>
                                        <span class="color_text"> whatsapp/</span>
                                        @creabik
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fa fa-youtube"></i>
                                        <span class="color_text">youtube/</span>
                                        @creabik
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p class="text-center txt_sm mt-20 color_text">Since 2021</p>
            </div>
        </div>
        <div class="col-lg-9 col-md-12 order-md-1 order-0">
            <div class="profile__content">
                <div class="d-flex justify-content-between">
                    <div class="space-x-10">
                        <div class="d-flex justify-content-between">
                            <ul class="nav nav-tabs d-flex space-x-10 mb-30"
                                role="tablist">
                                <li class="nav-item">
                                    <a
                                        class="btn btn-white btn-sm active"
                                        data-toggle="tab"
                                        href="#tabs-1"
                                        role="tab">
                                        Creations</a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="btn btn-white btn-sm"
                                        data-toggle="tab"
                                        href="#tabs-2"
                                        role="tab">
                                        Collections</a>
                                </li>
                            </ul>
                            
                            <div class="dropdown d-none d-sm-block">
                                <button
                                    class="btn btn-white btn-sm dropdown-toggle"
                                    type="button"

                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                    Recent Active
                                </button>
                                <div
                                    class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another
                                        action</a>
                                    <a class="dropdown-item" href="#">Something
                                        else here</a>
                                </div>
                            </div>
                        </div>

                        <div class="tab-content">
                            <div class="tab-pane active" id="tabs-1"
                                role="tabpanel">
                                <div class="row mb-30_reset">
                                {this.state.mintImages.map((kryptoBird, key)=>{
                                return(
                                    <div class="col-xl-4 col-lg-6 col-md-6" key={key}>
                                        <div class="card__item three">
                                            <div class="card_body space-y-10">
                                                
                                                <div class="card_head">
                                                    <img
                                                        src={kryptoBird.url}
                                                        alt=""
                                                        />
                                                    <a href="#" class="likes
                                                        space-x-3">
                                                        <i
                                                            class="ri-heart-3-fill"></i>
                                                        <span class="txt_sm">1.2k</span>
                                                    </a>
                                                    {/* <div class="action">
                                                        <a href="#" class="btn
                                                            btn-primary btn-sm
                                                            btn_auction"
                                                            data-toggle="modal"
                                                            data-target="#popup_bid">
                                                            <i
                                                                class="ri-auction-line
                                                                color_white"></i>
                                                            Place Your Bid
                                                        </a>
                                                    </div> */}
                                                </div>
                                                
                                                <h6 class="card_title">
                                                    <a class="color_black"
                                                        href="">
                                                        {kryptoBird.details.title}
                                                    </a>
                                                </h6>

                                                <div class="card_footer d-block
                                                    space-y-10">
                                                    <div class="d-flex
                                                        justify-content-between
                                                        align-items-center">
                                                        <div class="creators
                                                            space-x-10">
                                                            <div class="avatars
                                                                -space-x-20">
                                                                <a href="">
                                                                    <img
                                                                        src="./img/avatars/avatar_1.png"
                                                                        alt="Avatar"
                                                                        class="avatar
                                                                        avatar-sm"
                                                                        />
                                                                </a>
                                                                <a href="">
                                                                    <img
                                                                        src="./img/avatars/avatar_2.png"
                                                                        alt="Avatar"
                                                                        class="avatar
                                                                        avatar-sm"
                                                                        />
                                                                </a>
                                                            </div>
                                                            <a href="">
                                                                <p
                                                                    class="avatars_name
                                                                    txt_sm">
                                                                    @luka_fenn..
                                                                </p>
                                                            </a>
                                                        </div>
                                                        <a href="#"
                                                            class="space-x-3">
                                                            <p
                                                                class="color_green
                                                                txt_sm">{kryptoBird.details.price}</p>
                                                        </a>
                                                    </div>
                                                    <div class="hr"></div>
                                                    <div

                                                        class="d-flex
                                                        align-items-center
                                                        space-x-10">
                                                        <i
                                                            class="ri-vip-crown-line"></i>
                                                        <p class="color_text
                                                            txt_sm"
                                                            >
                                                            Highest bid
                                                        </p>
                                                        <span class="color_black
                                                            txt_sm">0.022
                                                            ETH</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })} 
                                </div>
                            </div>
                            <div class="tab-pane" id="tabs-2" role="tabpanel">
                                <div class="row justify-content-center mb-30_reset">
                                    <div class="col-lg-6 col-md-6 col-sm-8">
                                        <div class="collections space-y-10 mb-30">
                                            <div class="collections_item">
                                                <div class="images-box space-y-10">
                                                    <div class="d-flex space-x-10">
                                                        <img 
                                                            src="./img/items/item_1.png"
                                                            alt="" />
                                                        <img 
                                                            src="./img/items/item_2.png"
                                                            alt="" />
                                                        <img 
                                                            src="./img/items/item_3.png"
                                                            alt="" />
                                                    </div>
                                                    <div>
                                                        <img src="./img/items/item_4.png"
                                                            alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="collections_footer justify-content-between">
                                                <h5 class="collection_title"><a href="">Creative Art collection
                                                    </a></h5>
                                                <a href="#" class="likes space-x-3">
                                                    <i class="ri-heart-3-fill"></i>
                                                    <span class="txt_md">2.1k</span>
                                                </a>
                                            </div>
                                            <div class="creators space-x-10">
                                                <span class="color_text txt_md"> 5 items · Created by</span>
                                                <div class="avatars space-x-5">
                                                    <a href="">
                                                        <img
                                                            src="./img/avatars/avatar_1.png"
                                                            alt="Avatar" class="avatar avatar-sm" />
                                                    </a>
                                                </div>
                                                <a href="">
                                                    <p class="avatars_name txt_sm"> @william_jamy... </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-8">
                                        <div class="collections space-y-10 mb-30">
                                            <div class="collections_item">
                                                <div class="images-box space-y-10">
                                                    <div class="d-flex space-x-10">
                                                        <img 
                                                            src="./img/items/item_5.png"
                                                            alt="" />
                                                        <img 
                                                            src="./img/items/item_6.png"
                                                            alt="" />
                                                        <img 
                                                            src="./img/items/item_7.png"
                                                            alt="" />
                                                    </div>
                                                    <div>
                                                        <img src="./img/items/item_8.png"
                                                            alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="collections_footer justify-content-between">
                                                <h5 class="collection_title"><a href="">Colorful Abstract collection
                                                    </a></h5>
                                                <a href="#" class="likes space-x-3">
                                                    <i class="ri-heart-3-fill"></i>
                                                    <span class="txt_md">3.5k</span>
                                                </a>
                                            </div>
                                            <div class="creators space-x-10">
                                                <span class="color_text txt_md"> 7 items · Created by</span>
                                                <div class="avatars space-x-5">
                                                    <a href="">
                                                        <img
                                                            src="./img/avatars/avatar_2.png"
                                                            alt="Avatar" class="avatar avatar-sm" />
                                                    </a>
                                                </div>
                                                <a href="">
                                                    <p class="avatars_name txt_sm"> @alexis_fenn... </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-8">
                                        <div class="collections space-y-10 mb-30">
                                            <div class="collections_item">
                                                <div class="images-box space-y-10">
                                                    <div class="d-flex space-x-10">
                                                        <img 
                                                            src="./img/items/item_2.png"
                                                            alt="" />
                                                        <img 
                                                            src="./img/items/item_6.png"
                                                            alt="" />
                                                        <img 
                                                            src="./img/items/item_3.png"
                                                            alt="" />
                                                    </div>
                                                    <div>
                                                        <img src="./img/items/item_7.png"
                                                            alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="collections_footer justify-content-between">
                                                <h5 class="collection_title"><a href="">Photography Art collection
                                                    </a></h5>
                                                <a href="#" class="likes space-x-3">
                                                    <i class="ri-heart-3-fill"></i>
                                                    <span class="txt_md">7.2k</span>
                                                </a>
                                            </div>
                                            <div class="creators space-x-10">
                                                <span class="color_text txt_md"> 2 items · Created by</span>
                                                <div class="avatars space-x-5">
                                                    <a href="">
                                                        <img
                                                            src="./img/avatars/avatar_3.png"
                                                            alt="Avatar" class="avatar avatar-sm" />
                                                    </a>
                                                </div>
                                                <a href="">
                                                    <p class="avatars_name txt_sm"> @Joshua_Bren... </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-8">
                                        <div class="collections space-y-10 mb-30">
                                            <div class="collections_item">
                                                <div class="images-box space-y-10">
                                                    <div class="d-flex space-x-10">
                                                        <img 
                                                            src="./img/items/item_1.png"
                                                            alt="" />
                                                        <img 
                                                            src="./img/items/item_2.png"
                                                            alt="" />
                                                        <img 
                                                            src="./img/items/item_3.png"
                                                            alt="" />
                                                    </div>
                                                    <div>
                                                        <img src="./img/items/item_4.png"
                                                            alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="collections_footer justify-content-between">
                                                <h5 class="collection_title"><a href="">Fantasy Art collection
                                                    </a></h5>
                                                <a href="#" class="likes space-x-3">
                                                    <i class="ri-heart-3-fill"></i>
                                                    <span class="txt_md">2.1k</span>
                                                </a>
                                            </div>
                                            <div class="creators space-x-10">
                                                <span class="color_text txt_md"> 5 items · Created by</span>
                                                <div class="avatars space-x-5">
                                                    <a href="">
                                                        <img
                                                            src="./img/avatars/avatar_4.png"
                                                            alt="Avatar" class="avatar avatar-sm" />
                                                    </a>
                                                </div>
                                                <a href="">
                                                    <p class="avatars_name txt_sm"> @william_jamy... </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-8">
                                        <div class="collections space-y-10 mb-30">
                                            <div class="collections_item">
                                                <div class="images-box space-y-10">
                                                    <div class="d-flex space-x-10">
                                                        <img 
                                                            src="./img/items/item_5.png"
                                                            alt="" />
                                                        <img 
                                                            src="./img/items/item_6.png"
                                                            alt="" />
                                                        <img 
                                                            src="./img/items/item_7.png"
                                                            alt="" />
                                                    </div>
                                                    <div>
                                                        <img src="./img/items/item_8.png"
                                                            alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="collections_footer justify-content-between">
                                                <h5 class="collection_title"><a href="">Pop Art collection
                                                    </a></h5>
                                                <a href="#" class="likes space-x-3">
                                                    <i class="ri-heart-3-fill"></i>
                                                    <span class="txt_md">3.5k</span>
                                                </a>
                                            </div>
                                            <div class="creators space-x-10">
                                                <span class="color_text txt_md"> 7 items · Created by</span>
                                                <div class="avatars space-x-5">
                                                    <a href="">
                                                        <img
                                                            src="./img/avatars/avatar_5.png"
                                                            alt="Avatar" class="avatar avatar-sm" />
                                                    </a>
                                                </div>
                                                <a href="">
                                                    <p class="avatars_name txt_sm"> @alexis_fenn... </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-8">
                                        <div class="collections space-y-10 mb-30">
                                            <div class="collections_item">
                                                <div class="images-box space-y-10">
                                                    <div class="d-flex space-x-10">
                                                        <img 
                                                            src="./img/items/item_2.png"
                                                            alt="" />
                                                        <img 
                                                            src="./img/items/item_6.png"
                                                            alt="" />
                                                        <img 
                                                            src="./img/items/item_3.png"
                                                            alt="" />
                                                    </div>
                                                    <div>
                                                        <img src="./img/items/item_7.png"
                                                            alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="collections_footer justify-content-between">
                                                <h5 class="collection_title"><a href="">Contemporary Art collection
                                                    </a></h5>
                                                <a href="#" class="likes space-x-3">
                                                    <i class="ri-heart-3-fill"></i>
                                                    <span class="txt_md">7.2k</span>
                                                </a>
                                            </div>
                                            <div class="creators space-x-10">
                                                <span class="color_text txt_md"> 2 items · Created by</span>
                                                <div class="avatars space-x-5">
                                                    <a href="">
                                                        <img
                                                            src="./img/avatars/avatar_6.png"
                                                            alt="Avatar" class="avatar avatar-sm" />
                                                    </a>
                                                </div>
                                                <a href="">
                                                    <p class="avatars_name txt_sm"> @Joshua_Bren... </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
            

                </div>
            </>
        )
    }
}
export default Profile;