import React,{ Component } from "react";
import '../CSS/newRecord.css';
import '../CSS/login.css'
import Sidemenu from './Navbar/Sidemenu';
import GenericNavbar from './Navbar/GenericNavbar';


class Dashboard extends Component{
    constructor(prompt){
        super(prompt);
        this.state ={}
    }
    render(){
        return(
            <>
            <div className = "">
                <GenericNavbar/>
                <div className="container-fluid bg-black">
                <div className="row">
                    <Sidemenu />
                   
                    <div className="col-sm-10">
                        <div className="card reportCard">
                            <div className="card-title cardTitle2">
                                <h4 className = "cardTitle">List</h4>
                            </div>
                            <div className="card-content">
                            <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>User</th>
                                    <th>No. issue</th>
                                    <th>Per price</th>
                                    <th>ETH </th>
                                    <th>Address</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                        </table>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div></>
        );
    }
}
export default Dashboard;