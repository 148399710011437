import React ,{ Component } from "react";
import Navbar from "./Navbar";

import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import KryptoBird from '../abis/KryptoBird.json'

class Collections extends Component{
    constructor(props){
        super(props);
        this.state = {
			account: '',
			contract:null,
			totalSupply:0,
			kryptoBirdz:[],
			price:'',
			title:'',
			desc:'',
			name:'',
			mintImages: [],
			show:false,
			fileSize:'',
			fileType:'',
		}
    }
	async componentDidMount() {
		/* var user = localStorage.getItem('user');
		if(!user){
		   this.props.history.push('/login');
		} */
		await this.loadWeb3();
		await this.loadBlockchainData();
		await this.gelAllMint();
	}
	gelAllMint(){
		fetch(`
		http://52.60.64.107/api/nft/mint-all.php`)
					  .then(response => response.json())
					  .then(data => {
						if(! data.error){
						  let getData =[];
						  for (let i = 0; i < this.state.kryptoBirdz.length; i++) {
							 let link = this.state.kryptoBirdz[i];
							 let details = '';
							 for (let x = 0; x < data.result.length; x++) {
								if(link==data.result[x].url){
								   details = data.result[x];
								}
								if((data.result.length-1)==x){
								   getData.push({url: link, details: details});
								}
							 }                        
						  }
						  console.log(getData)
						  this.setState({mintImages: getData});
						}
					  });
	   }

	async loadWeb3() {
		const provider = await detectEthereumProvider();
  
		// modern browsers
		// if there is a provider then lets
		// lets log that it's working and access the window from the doc
		// to set Web3 to the provider 
		
		if(provider) {
			console.log('ethereum wallet is connected')
			window.web3 = new Web3(provider)
		} else {
			// no ethereum provider
			console.log('no ethereum wallet detected')
		}
	}
  
	async loadBlockchainData() {
		const web3 = window.web3
		const accounts = await web3.eth.getAccounts()
		this.setState({account:accounts[0]})
  
		// create a constant js variable networkId which 
		//is set to blockchain network id 
		const networkId = await web3.eth.net.getId()
		const networkData = KryptoBird.networks[networkId]
		 if(networkData) {
			 // EXERCISE TIME!!!! :)
			 // 1. create a var abi set to the Kryptobird abi
			 // 2. create a var address set to networkData address
			 // 3. create a var contract which grabs a 
			 //new instance of web3 eth Contract  
			 // 4. log in the console the var contract successfully - GOOD LUCK!!!!
  
			 const abi = KryptoBird.abi;
			 const address = networkData.address; 
			 const contract = new web3.eth.Contract(abi, address)
			 this.setState({contract})
  
			 // call the total supply of our Krypto Birdz 
			 // grab the total supply on the front end and log the results
			 // go to web3 doc and read up on methods and call 
			 const totalSupply = await contract.methods.totalSupply().call()
			this.setState({totalSupply})
			// set up an array to keep track of tokens 
			// load KryptoBirdz
			for(let i = 1; i <= totalSupply; i++) {
				const KryptoBird = await contract.methods.kryptoBirdz(i - 1).call()
				// how should we handle the state on the front end? 
				this.setState({
					kryptoBirdz:[...this.state.kryptoBirdz, KryptoBird]
				})
			}
		 } else {
			 //window.alert('Smart contract not deployed')
		 }
	}

	mint = (kryptoBird) => {
		this.state.contract.methods.mint(kryptoBird).send({from:this.state.account})
		.once('receipt', (receipt)=> {
			this.setState({
				kryptoBirdz:[...this.state.kryptoBirdz, KryptoBird]
			})
		})  
	}
    render(){
        return(
            <>
                <div className="overflow-hidden">
                <Navbar />
                <div class="container">
			    <div class="section mt-100">
					<div>
					<form onSubmit={(event)=>{
                                event.preventDefault()
                                console.log(this.kryptoBird.value);
                                console.log(this.state);
                                /******* */
                                const kryptoBird = this.kryptoBird.value
                                this.mint(kryptoBird);

                                fetch(`
                     http://52.60.64.107/api/nft/mint.php?name=${this.state.name}&url=${this.kryptoBird.value}&
                           desc=${this.state.desc}&title=${this.state.title}&price=${this.state.price}&account=${this.state.account}
                           &filtype=${this.state.fileType}&filesize=${this.state.fileSize}`)
                                 .then(response => response.json())
                                 .then(data => {
                                       this.setState({title: ''});
                                       this.setState({name: ''});
                                       this.setState({desc: 0});
                                       this.setState({price: ''});
                                       this.setState({fileType: ''});
                                       this.setState({fileSize: ''});
                                       this.gelAllMint();

                                 });
                                /******* */


                                /*  */
                            }}>
                               <div className="row">
                                 <div className="col-lg-6">
                                    <input type="text" placeholder='Title' className='form-control mb-1'
                                    value={this.state.title}
                                    onChange={(e)=>this.setState({title: e.target.value}) }
                                    />
                                 </div>
                                 <div className="col-lg-6">
                                    <input type="text" placeholder='Description' className='form-control mb-1'
                                    value={this.state.desc}
                                    onChange={(e)=>this.setState({desc: e.target.value}) }
                                    />
                                 </div>
                                 <div className="col-lg-6">
                                    <input type="text" placeholder='Author Name' className='form-control mb-1'
                                    value={this.state.name}
                                    onChange={(e)=>this.setState({name: e.target.value }) }
                                    />
                                 </div>
                                 <div className="col-lg-6">
                                    <input type="text" placeholder='Price (ETH)' className='form-control mb-1' 
                                    value={this.state.price}
                                    onChange={(e)=>this.setState({price: e.target.value}) }
                                    />
                                 </div>
                               </div>

                                <input
                                type='text'
                                placeholder='Add a file location'
                                className='form-control mb-1'
                                ref={(input)=>this.kryptoBird = input}
                                />
                                <div className="row">
                                    <div className="col-lg-6">
                                        <input type="text" placeholder='Size' className='form-control mb-1'
                                        value={this.state.fileSize}
                                        onChange={(e)=>this.setState({fileSize: e.target.value }) }
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <input type="text" placeholder='File type' className='form-control mb-1' 
                                        value={this.state.fileType}
                                        onChange={(e)=>this.setState({fileType: e.target.value}) }
                                        />
                                    </div>
                                </div>
                                <input style={{margin:'6px'}}
                                type='submit'
                                className='btn btn-gradient-primary'
                                value='MINT'
                                />
                      </form>
					</div>
			        <div class="section__head">
			            <h2 class="section__title mb-20"> Artworks</h2>
			            <div class="row justify-content-between align-items-center">
			                <div class="col-lg-auto">
			                    <div class="d-flex align-items-center">
			                        <span class="color_text txt_sm d-none d-sm-block mr-10">
			                            FILTER BY:
			                        </span>
			                        <ul class="menu_categories">
			                            <li class="d-flex switch_item">
			                        
			                                <input type="checkbox" id="switch1" /><label
			                                    for="switch1">Toggle</label>
			                                <span class="ml-10"> Has list price </span>
			                            </li>
			                            <li class="d-flex switch_item">
			                        
			                                <input type="checkbox" id="switch2" checked/><label
			                                    for="switch2">Toggle</label>
			                                <span class="ml-10"> Has open offer </span>
			                            </li>
			                            <li class="d-flex switch_item">
			                        
			                                <input type="checkbox" id="switch3" /><label
			                                    for="switch3">Toggle</label>
			                                <span class="ml-10"> Owned by creator </span>
			                            </li>
			                            <li class="d-flex switch_item">
			                        
			                                <input type="checkbox" id="switch4" /><label
			                                    for="switch4">Toggle</label>
			                                <span class="ml-10"> Has sold </span>
			                            </li>
			                        </ul>                    </div>
			                </div>
			                <div class="col-lg-auto">
			                    <div class="d-flex space-x-10 align-items-center sm:mt-20">
			                        <span class="color_text txt_sm"> SORT BY: </span>
			                        <div class="dropdown">
			                            <button
			                                class="btn btn-dark btn-sm dropdown-toggle"
			                                type="button"
			                                data-toggle="dropdown"
			                                aria-haspopup="true"
			                                aria-expanded="false">
			                                Recent Active
			                            </button>
			                            <div class="dropdown-menu">
			                                <a class="dropdown-item" href="#">Action</a>
			                                <a class="dropdown-item" href="#">Another action</a>
			                                <a class="dropdown-item" href="#">Something else
			                                    here</a>
			                            </div>
			                        </div>
			                    </div>
			                </div>
			            </div>
			        </div>
			
					<div class="row mb-30_reset">
					{this.state.mintImages.map((kryptoBird, key)=>{
                                return(
			        	<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6"
						key={key}>
			        		<div class="card__item four">
			        			<div class="card_body space-y-10">
			        				<div class="creators space-x-10">
			        					<div class="avatars space-x-3">
			        						<a href="Profile.html">
			        							<img
			        								src="./img/avatars/avatar_1.png"
			        								alt="Avatar" class="avatar avatar-sm" />
			        						</a>
			        						<a href="Profile.html">
			        							<p class="avatars_name txt_xs">@mickel_fenn</p>
			        						</a>
			        					</div>
			        					<div class="avatars space-x-3">
			        						<a href="Profile.html">
			        							<img
			        								src="./img/avatars/avatar_2.png"
			        								alt="Avatar" class="avatar avatar-sm" />
			        						</a>
			        						<a href="Profile.html">
			        							<p class="avatars_name txt_xs">@danil_pannini</p>
			        						</a>
			        					</div>
			        				</div>
			        				<div class="card_head">
			        					<a href="Item-details.html">
			        						<img 
											src={kryptoBird.url}
			        							alt="" />
			        					</a>
			        
			        					<a href="#" class="likes space-x-3">
			        						<i class="ri-heart-3-fill"></i>
			        						<span class="txt_sm">1.2k</span>
			        					</a>
			        				</div>
			        				<h6 class="card_title">
			        					Colorful Abstract Painting
			        				</h6>
			        				<div class="card_footer d-block space-y-10">
			        					<div class="card_footer justify-content-between">
			        						<div class="creators">
			        							<p class="txt_sm"> 4 in stock</p>
			        						</div>
			        						<a href="#" class="">
			        							<p class="txt_sm">Price: <span
			        									class="color_green
			        									txt_sm">2.45 ETH</span></p>
			        						</a>
			        					</div>
			        					<div class="hr"></div>
			        					<div class="d-flex align-items-center space-x-10
			        						justify-content-between">
			        						<div class="d-flex align-items-center
			        							space-x-10">
			        							<i class="ri-history-line"></i>
			        							<a class="view_history" href="#" data-toggle="modal"
			        								data-target="#popup_history">
			        								<p class="color_text txt_sm"
			        									>View
			        									History</p>
			        							</a>
			        						</div>
			        						<a class="btn btn-sm btn-primary" href="#"
			        							data-toggle="modal" data-target="#popup_bid">Place
			        							Bid</a>
			        					</div>
			        				</div>
			        			</div>
			        		</div>
			        	</div>
								)
                            })} 
			        </div>    
				</div>
                </div>
            </div>
            </>
        );
    }
}
export default Collections;