import React, {Component} from 'react';

class GenericNavbar extends Component{
    render()
    {
        return(
            <nav className="nav-wrapper grey darken-4 navbar">
            <div className="container-fluid">
            <a href="/" className="brand-logo">
            <img
            className="header__logo"
            id="logo_js"
            src="./logo_nft.png"
            alt="logo"
            style={{height:'60px'}}
            />
                            </a>
               <ul className = "right">
               </ul>
            </div>
        </nav>
        )
    }

}

export default GenericNavbar;
