import React,{ Component } from "react";
import '../CSS/newRecord.css';
import '../CSS/login.css'
import Sidemenu from './Navbar/Sidemenu';
import GenericNavbar from './Navbar/GenericNavbar';


class Bidlist extends Component{
    constructor(prompt){
        super(prompt);
        this.state ={
            bidlist:[]
        }
    }
    componentDidMount(){
        fetch(`http://52.60.64.107/api/nft/bid-report.php`)
        .then(response=>response.json())
        .then(data=>{
            this.setState({bidlist: data.result});
        })
    }
    render(){
        return(
            <>
            <div className = "">
                <GenericNavbar/>
                <div className="container-fluid bg-black">
                <div className="row">
                    <Sidemenu />
                   
                    <div className="col-sm-10">
                        <div className="card reportCard">
                            <div className="card-title cardTitle2">
                                <h4 className = "cardTitle">List</h4>
                            </div>
                            <div className="card-content">
                            <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Image</th>
                                    <th>Address</th>
                                    <th>Hight bid</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.bidlist && this.state.bidlist.length >0 &&
                                <>
                                {this.state.bidlist.map((bid,indx)=>
                                    <tr key={indx}>
                                        <td>{indx+1}</td>
                                        <td><img style={{height:'50px' }} src={bid.url} /></td>
                                        <td>{ bid.account } </td>
                                        <td>{bid.max_bid} ETH</td>
                                        <td>{ bid.create_date } </td>
                                    </tr>
                                )}
                                </>
                                 }
                            </tbody>
                        </table>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div></>
        );
    }
}
export default Bidlist;