import React, { Component } from 'react';
import Navbar from './Navbar';

class Registrion extends Component {
    constructor(props) {
        super(props);
        this.state ={
            name:'',
            url:'',
            email:'',
            bio:'',
        };
        this.register = this.register.bind(this);
    }

    register(){
        console.log(this.state);
        fetch(`
      http://52.60.64.107/api/nft-new/sign-up.php?name=${this.state.name}&bio=${this.state.bio}&
            url=${this.state.url}&
            email=${this.state.email}`)
                    .then(response => response.json())
                    .then(data => {
                      this.props.history.push('/login');
                      this.setState({bio: ''});
                        this.setState({name: ''});
                        this.setState({url: ''});
                        this.setState({email: ''});
                    });
    }

    render() {

        return (
            <div> 
                <div className="overflow-hidden">
                <Navbar />
                <div class="edit_profile register">
				<div class="container">
					<div class="row">
						<div class="col-lg-3"></div>
						<div class="col-lg-9">
							<div class="right_part space-y-20">
								<h1 class="color_white"> Register new account</h1>
								<p class="color_white" 
									>You can set preferred display name, create your profile URL and
									manage other personal settings.</p
								>
								<div class="box edit_box w-full space-y-20">
									<div class="row">
										<div class="col-lg-6 account-info">
											<div class="avatars space-x-20 mb-30">
												<div id="profile-container">
													<img
														id="profileImage"
														src="./img/avatars/avatar_3.png"
														alt="Avatar"
														class="avatar avatar-lg border-0"
													/>
												</div>
												<div>
													<h6 class="mb-1">Profile photo</h6>
													<p class="mb-1"
														>We recommend an image of at least 400x400. Gifs work too
														🙌</p
													>
													<div id="boxUpload">
														<a href="#" class="btn btn-sm btn-dark"> Upload </a>
														<input
															id="imageUpload"
															type="file"
															name="profile_photo"
															placeholder="Photo"
															required=""
															capture=""
														/>
													</div>
												</div>
											</div>
											<h3 class="mb-20"> 🍉 Account info </h3>
											<div class="form-group space-y-10 mb-0">
												<div class="space-y-20">
													<div class="space-y-10">
														<span class="nameInput">Display name</span>
														<input
															type="text"
															class="form-control"
															placeholder="your name"
                                                            value={this.state.name}
                                                            onChange={(e)=>this.setState({name:e.target.value }) }
														/>
													</div>
													<div class="space-y-10">
														<span class="nameInput">Custom URL</span>
														<input
															type="text"
															class="form-control"
															placeholder="NFT LIMITED SERIES.com/"
                                                            value={this.state.url}
                                                            onChange={(e)=>this.setState({url: e.target.value})}
														/>
													</div>
													<div class="space-y-10">
														<span class="nameInput d-flex justify-content-between"
															>Email
															<span class="txt_xs color_text"
																>Your email for marketplace notifications</span
															>
														</span>
														<div class="confirm">
															<input
																type="text"
																class="form-control"
																placeholder="Enter email"
                                                                value={this.state.email}
                                                                onChange={(e)=>this.setState({email: e.target.value})}
															/>
															{/* <a href="#" class="confirm-btn btn btn-dark btn-sm"
																>Confirm</a
															> */}
														</div>
													</div>
													<div class="space-y-10">
														<span class="nameInput">Bio</span>
														<textarea
															value={this.state.bio}
                                                            onChange={(e)=>this.setState({ bio: e.target.value})}
															placeholder="Add your bio"
														/>
													</div>
												</div>
											</div>
										</div>
										<div class="col-lg-6 social-media">
											<h3 class="mb-20">👨 Social media ‍</h3>
											<div class="form-group space-y-10">
												<div class="space-y-20">
													<div class="d-flex flex-column">
														<span class="nameInput mb-10">Facebook</span>
														<input
															type="text"
															class="form-control"
															placeholder="facebook username"
														/>
														<a
															class="facebook-btn btn btn-primary mt-10 btn-sm"
															href="#"
														>
															<i class="ri-facebook-circle-fill"></i>
															Connect to Facebook
														</a>
													</div>
													<div class="d-flex flex-column">
														<span class="nameInput mb-10">Twitter</span>
														<input
															type="text"
															class="form-control"
															placeholder="twitter username"
														/>
														<a
															class="twitter-btn btn btn-primary mt-10 btn-sm"
															href="#"
														>
															<i class="ri-twitter-fill"></i>
															Connect to Twitter
														</a>
													</div>
													<div class="d-flex flex-column">
														<span class="nameInput mb-10">Discord</span>
														<input
															type="text"
															class="form-control"
															placeholder="discord username"
														/>
														<a
															class="discord-btn btn btn-primary mt-10 btn-sm"
															href="#"
														>
															<i class="ri-discord-fill"></i>
															Connect to Discord
														</a>
													</div>
												</div>
											</div>
											{/* <h3 class="mb-20 mt-40">📮 Notifications </h3>
											<ul class="space-y-10">
												<li class="d-flex space-x-10 switch_item">
													<input type="checkbox" id="switch1" checked="" /><label
														for="switch1"
														>Toggle</label
													>
													<span class="color_text"> Email Notifications </span>
												</li>
												<li class="d-flex space-x-10 switch_item">
													<input type="checkbox" id="switch2" checked="" /><label
														for="switch2"
														>Toggle</label
													>
													<span class="color_text"> New Bids </span>
												</li>
												<li class="d-flex space-x-10 switch_item">
													<input type="checkbox" id="switch3" checked="" /><label
														for="switch3"
														>Toggle</label
													>
													<span class="color_text"> Item Purchased </span>
												</li>
												<li class="d-flex space-x-10 switch_item">
													<input type="checkbox" id="switch4" checked="" /><label
														for="switch4"
														>Toggle</label
													>
													<span class="color_text"> People Followed </span>
												</li>
											</ul> */}
										</div>
									</div>
									<div class="hr"></div>
									<p class="color_black"
										>Please take a few minutes to read and understand Stacks Terms of
										Service. To continue, you’ll need to accept the terms of services
										by checking the boxes.</p
									>
									<button type='button' class="btn btn-grad" onClick={this.register} >Register</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

                </div>
            </div>
        );
    }
}

export default Registrion;