import React ,{ Component } from "react";
import Navbar from "./Navbar";

import axios from 'axios';

import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import KryptoBird from '../abis/KryptoBird.json'

class Upload extends Component{
    constructor(props){
        super(props);
        this.state = {
			account: '',
			contract:null,
			totalSupply:0,
			kryptoBirdz:[],
			price:'',
			title:'',
			desc:'',
			name:'',
			mintImages: [],
			show:false,
			fileSize:'',
			fileType:'',
			category:'',
			progress: 0,
			links: '',
			minMrice:'',
			user:'',
		}
		this.handleChange = this.handleChange.bind(this);
    }
	onUploadProgress(event){
		//const percentCompleted = Math.round((event.loaded * 100) / event.total);
		this.setState({
			progress: Math.round((100 * event.loaded) / event.total),
		  });
		//console.log('onUploadProgress', percentCompleted);
	};
	
	handleChange(selectorFiles, event){
		console.log(selectorFiles.loaded)
		this.setState({
			progress: Math.round((100 * event.loaded) / event.total),
		  });
		var formData = new FormData();
		for (let i = 0; i < selectorFiles.length; i++) {
			formData.set('file_'+i, selectorFiles[i], selectorFiles[i].name);
		}
		const option = {
            onUploadProgress : (ProgressEvent) => {
                const {loaded, total} = ProgressEvent;
                let percent = Math.floor(loaded * 100 / total);
				this.setState({progress: percent})
				console.log(percent)
                //console.log('tes get value for progress upload : ',`${loaded} kb of ${total} kb | ${percent}%`)
            }
        }
		var request = axios.post("http://52.60.64.107/api/nft/ulpload.php", formData, option); 
		request.then(function(response){ 
			console.log(response)
			console.log(response.data.result)
			if(response.statusText =='OK'){
				//this.setState({links: response.data.result })
			}
		})
		
    }
	async componentDidMount() {
		var user = localStorage.getItem('user');
		if(!user){
		   this.props.history.push('/login');
		}else{
			this.setState({user: JSON.parse(user) })
		}
		await this.loadWeb3();
		await this.loadBlockchainData();
		await this.gelAllMint();
	}
	gelAllMint(){
		fetch(`
		http://52.60.64.107/api/nft/mint-all.php`)
					  .then(response => response.json())
					  .then(data => {
						if(! data.error){
						  let getData =[];
						  for (let i = 0; i < this.state.kryptoBirdz.length; i++) {
							 let link = this.state.kryptoBirdz[i];
							 let details = '';
							 for (let x = 0; x < data.result.length; x++) {
								if(link==data.result[x].url){
								   details = data.result[x];
								}
								if((data.result.length-1)==x){
								   getData.push({url: link, details: details});
								}
							 }                        
						  }
						  console.log(getData)
						  this.setState({mintImages: getData});
						}
					  });
	   }

	async loadWeb3() {
		const provider = await detectEthereumProvider();
  
		// modern browsers
		// if there is a provider then lets
		// lets log that it's working and access the window from the doc
		// to set Web3 to the provider 
		
		if(provider) {
			console.log('ethereum wallet is connected')
			window.web3 = new Web3(provider)
		} else {
			// no ethereum provider
			console.log('no ethereum wallet detected')
		}
	}
  
	async loadBlockchainData() {
		const web3 = window.web3
		const accounts = await web3.eth.getAccounts()
		this.setState({account:accounts[0]})
		console.log(accounts[0])
		// create a constant js variable networkId which 
		//is set to blockchain network id 
		const networkId = await web3.eth.net.getId()
		const networkData = KryptoBird.networks[networkId];
		console.log(networkId)
		console.log(networkData);
		 if(networkData) {
			 // EXERCISE TIME!!!! :)
			 // 1. create a var abi set to the Kryptobird abi
			 // 2. create a var address set to networkData address
			 // 3. create a var contract which grabs a 
			 //new instance of web3 eth Contract  
			 // 4. log in the console the var contract successfully - GOOD LUCK!!!!
  
			 const abi = KryptoBird.abi;
			 const address = networkData.address; 
			 const contract = new web3.eth.Contract(abi, address)
			 this.setState({contract})
  
			 // call the total supply of our Krypto Birdz 
			 // grab the total supply on the front end and log the results
			 // go to web3 doc and read up on methods and call 
			 const totalSupply = await contract.methods.totalSupply().call()
			this.setState({totalSupply})
			// set up an array to keep track of tokens 
			// load KryptoBirdz
			for(let i = 1; i <= totalSupply; i++) {
				const KryptoBird = await contract.methods.kryptoBirdz(i - 1).call()
				// how should we handle the state on the front end? 
				this.setState({
					kryptoBirdz:[...this.state.kryptoBirdz, KryptoBird]
				})
			}
		 } else {
			 //('Smart contract not deployed')
		 }
	}

	mint = (kryptoBird) => {
		this.state.contract.methods.mint(kryptoBird).send({from:this.state.account})
		.once('receipt', (receipt)=> {
			this.setState({
				kryptoBirdz:[...this.state.kryptoBirdz, KryptoBird]
			})
		})  
	}
    render(){
        return(
            <>
                <div className="overflow-hidden">
                <Navbar />
                <div class="hero__upload">
			    <div class="container">
			        <div class="space-y-20">
			            <a href="/upload-multiple" class="btn btn-white btn-sm
			                switch">
			                Switch
			                to Multiple</a>
			            <h2 class="title">Create single collectible</h2>
			
			        </div>
			    </div>
			</div>
			<div class="container">
            <form onSubmit={(event)=>{
                                event.preventDefault()
                                console.log(this.kryptoBird.value);
                                console.log(this.state);
                                /******* */
                                const kryptoBird = this.kryptoBird.value
                                this.mint(kryptoBird);

                                fetch(`
                     http://52.60.64.107/api/nft/mint.php?name=${this.state.name}&url=${this.kryptoBird.value}&
                           desc=${this.state.desc}&title=${this.state.title}&price=${this.state.price}&account=${this.state.account}
                           &filtype=${this.state.fileType}&filesize=${this.state.fileSize}&category=${this.state.category}
						   &minPrice=${this.state.minMrice}&userId=${this.state.user.id}`)
                                 .then(response => response.json())
                                 .then(data => {
                                       this.setState({title: ''});
                                       this.setState({name: ''});
                                       this.setState({desc: 0});
                                       this.setState({price: ''});
                                       this.setState({fileType: ''});
                                       this.setState({fileSize: ''});
                                       this.gelAllMint();

                                 });
                                /******* */


                                /*  */
                            }}>
				<div class="box in__upload mb-120">
					<div class="row">
						<div class="col-lg-6">
							<div class="left__part space-y-40 md:mb-20 upload_file">
								<div class="space-y-20">
									<img class="icon"
										src="./img/icons/upload.svg"
										alt="" />
									<p class="color_text">PNG, GIF, WEBP, MP4 or MP3. Max
										100mb.</p>
									</div>
								
							{this.state.links}
								<div class="space-y-20">
									<input type="text" placeholder="Mint" 
                                    ref={(input)=>this.kryptoBird = input}/>
			
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="form-group space-y-10">
								<div class="space-y-20">
									<div class="space-y-10">
										<span class="nameInput">Title</span>
										<input type="text" class="form-control"
											placeholder="Title" 
                                            value={this.state.title}
                                            onChange={(e)=>this.setState({title: e.target.value}) }
                                            />
									</div>
									<div class="space-y-10">
										<span class="nameInput">Author Name<span
												class="color_text"> </span></span>
												<div className="col-lg-12">
                                    <input type="text" placeholder='Author Name' className='form-control mb-1'
                                    value={this.state.name}
                                    onChange={(e)=>this.setState({name: e.target.value }) }
                                    />
									</div></div>
									<div class="space-y-10">
										<span class="nameInput">Description <span
												class="color_text">
												(optional) </span></span>
										<input type="text" class="form-control"
											placeholder="Description" 
                                            value={this.state.desc}
                                            onChange={(e)=>this.setState({desc: e.target.value}) }
                                            />
									</div>
									<div class="space-y-10">
										<span class="variationInput">Price</span>
										<select class="form-select custom-select"
											aria-label="Default select example"
                                            value={this.state.price}
                                            onChange={(e)=>this.setState({price: e.target.value}) }
                                            >
											<option> 00.00 ETH</option>
											<option>01.00 ETH</option>
											<option>02.00 ETH</option>
											<option>10.00 ETH</option>
											<option>20.00 ETH</option>
										</select>
									</div>
									<div class="space-y-10">
										<span class="variationInput">Minimum Price</span>
										<input type="text" placeholder="ETH"
										value={this.state.minMrice}
										onChange={(e)=>this.setState({minMrice: e.target.value}) }
										  />
									</div>
									<div class="space-y-10">
										<span class="variationInput">Choose Type</span>
										<select class="form-select custom-select"
											aria-label="Default select example"
                                            value={this.state.category}
                                            onChange={(e)=>this.setState({category: e.target.value}) }
                                            >
											<option value='Games'> Games</option>
											<option value='Art'>Art</option>
											<option value='Trading'>Trading</option>
											<option value='Music'>Music</option>
											<option value='Domain'>Domain Names</option>
											<option value='Memes'>Memes</option>
											<option value='Collectibles'>Collectibles</option>
										</select>
									</div>
                                    <input style={{margin:'6px'}}
                                        type='submit'
                                        className='btn btn-primary'
                                        value='MINT'
                                        />
			
			
								</div>
							</div>
							{/* <p class="color_black">
								<span class="color_text text-bold"> Service fee : </span>
								2.5%
							</p> <p class="color_black">
								<span class="color_text text-bold"> You will receive :
								</span>
								22.425 ETH $41,637.78
							</p> */}
							<p>
			
			
							</p>
						</div>
					</div>
				</div>
                </form>
			</div>
            </div>
            </>
        );
    }
}
export default Upload;