
import React, { Component }  from 'react';
import './App.css';
/* import './pages/custom.css'; */
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';


import Login from "./components/Login";
import Home from "./components/Home";
import Registrion from "./components/Registrion";
import MarketPlace from './components/MarketPlace';
import Profile from './components/Profilel';
import History from './components/History';
import Collections from './components/Collection';
import Upload from './components/Upload';
import Details from './components/Details';
import UploadType from './components/UploadType';
import UploadMultiple from './components/UploadMultiple';
import  Dashboard  from './components/admin/dashboard';
import Userlist from './components/admin/Userlist';
import Mintlist from './components/admin/Mintlist';
import Bidlist from './components/admin/Bidlist';
import Mint from './components/admin/Mint';



function App() {
  return (
    <Router>
    <div id="container">
      <Switch>
      <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/registrion" component={Registrion} />
        <Route exact path="/marketplace" component={MarketPlace} />
        <Route exact path="/collection" component={ Collections } />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/upload" component={ Upload} />
        <Route exact path="/upload-type" component={ UploadType} />
        <Route exact path="/upload-multiple" component={ UploadMultiple} />
        <Route exact path="/details" component={ Details} />
        <Route exact path="/history" component={ History } />

        <Route exact path="/admin" component={ Userlist } />
        <Route exact path="/user-report" component={ Userlist } />
        <Route exact path="/mint-report" component={ Mintlist } />
        <Route exact path="/bid-report" component={ Bidlist } />
        <Route exact path="/mint" component={ Mint } />

      </Switch>
    </div>
  </Router>
  );
}

export default App;
