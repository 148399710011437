import React, { Component } from 'react';
import './bootstrap.min.css';
import './swiper-bundle.min.css';
import './style.css';
import Navbar from './Navbar';


class Login extends Component {
    constructor(props) {
        super(props);
        this.state ={
            email: '',
            password: '',
            errorEmailMesage: '',
            errorPasswordMesage: '',
        };
        this.login = this.login.bind(this);
    }
    login(){
        if(this.state.email ==''){
            this.setState({errorEmailMesage: 'Please enter email'});
            return
        }else{
            this.setState({errorEmailMesage: ''});
        }
        if(this.state.password ==''){
            this.setState({errorPasswordMesage: 'Please enter password'});
            return
        }else{
            this.setState({errorPasswordMesage: ''});
        }

        if(this.state.email !='' && this.state.password !=''  ){
            fetch(`http://52.60.64.107/api/nft-new/login.php?email=${this.state.email}&password=${this.state.password}`)
            .then(response => response.json())
            .then(data => {
                if(! data.error){
                    localStorage.setItem('user', JSON.stringify(data.result));
                    if(data.result.user_type=='1' || data.result.user_type==1){
                        this.props.history.push('/profile');
                    }else{
                        this.props.history.push('/admin');
                    }
                    
                }else{
                    this.setState({errorEmailMesage: 'Please enter valid email and password'});
                    console.log(data)
                }
            })
        }

    }

    render() {

        return (
            <div>
                <div className="overflow-hidden">
                <Navbar />
                <div className="edit_profile register login">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7"></div>
                            <div className="col-lg-5">
                                <div className="right_part space-y-20">
                                    <h1 className="color_white"> Welcome to NFT Limited Series </h1>
                                    <p className="color_white" 
                                        >Don't have an account yet? <a href="/registrion"> Register </a>
                                    </p>
                                    <div className="box edit_box w-full space-y-20">
                                        <div className="space-y-10">
                                            <span className="nameInput">Email </span>
                                            <div className="confirm">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    value={this.state.email}
                                                    onChange={(e)=>this.setState({email: e.target.value})}
                                                    style={{color:'black'}}
                                                />
                                                <span className='text-danger'>{this.state.errorEmailMesage}</span>
                                            </div>
                                        </div>
                                            <div className="space-y-10">
                                            <span className="nameInput">Password</span> 
                                            <div className="confirm">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Enter your password"
                                                    value={this.state.password}
                                                    onChange={(e)=>this.setState({password: e.target.value})}
                                                    style={{color:'black'}}
                                                />
                                                <span className='text-danger'>{this.state.errorPasswordMesage}</span>
                                            </div>
                                        </div>
                                            {/* <a className="btn btn-white btn-sm color_green"> Click to verify </a> */}
                                            <button type='button' onClick={this.login} className="btn btn-grad w-full btn-lg "> Login</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                </div>
            </div>
        );
    }
}

export default Login;